<template>
  <div class="help">
    <h1>Launchpad Help</h1>
    <p>Running into an issue? We're here to help! There are several ways to get in touch:</p>
    <ul>
      <li>If you encounter an error in Launchpad, you can <strong>check the
        Submit error checkbox on the dialog.</strong></li>
      <li>If you wish to provide feedback from the application, you can
        choose <strong>Tools > Provide Feedback</strong> from within Launchpad.</li>
      <li>You can follow along with what we're working on or submit issues of
        your own in the <strong><a href="https://github.com/VolantisDev/Launchpad/issues">Launchpad
        issue queue on GitHub</a></strong>.</li>
    </ul>
  </div>
</template>

<style scoped lang="stylus">
  ul li {
    list-style none
    margin-bottom 0.5em
  }
</style>
